import React from 'react'
import './styles.scss';
import { navigateTo } from 'gatsby-link'

import { Parallax, Background } from 'react-parallax'

import {
    Container,
    Col,
    Row,
    Carousel,
    CarouselItem,
    CarouselControl,
} from 'reactstrap'

import {
    Button
} from '../../components'

import Modal from 'react-responsive-modal';

import PourquoiIrriglobemp4 from '../../assets/video/pourquoi-irriglobe.mp4'
import PourquoiIrriglobewebm from '../../assets/video/pourquoi-irriglobe.webm'

import ControleurWifimp4 from '../../assets/video/controleur-wifi.mp4'
import ControleurWifiwebm from '../../assets/video/controleur-wifi.webm'

import SenseurPluiemp4 from '../../assets/video/senseur-pluie.mp4'
import SenseurPluiewebm from '../../assets/video/senseur-pluie.webm'

import AsperseurGicleurmp4 from '../../assets/video/asperseur-gicleur.mp4'
import AsperseurGicleurwebm from '../../assets/video/asperseur-gicleur.webm'

import Intromp4 from '../../assets/video/intro-new.mp4'
import Introwebm from '../../assets/video/intro-new.webm'

class HomeSlider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0,
            items: [0, 1, 2, 3],
            open0: false,
            open1: false,
            open2: false,
            open3: false,
        }
        this.next = this.next.bind(this)
        this.previous = this.previous.bind(this)
        this.goToIndex = this.goToIndex.bind(this)
        this.onExiting = this.onExiting.bind(this)
        this.onExited = this.onExited.bind(this)
    }

    componentDidMount() {
        try {
            document.getElementById('bgvideo').play();
        } catch (e) { }
    }

    onExiting() {
        this.animating = true
    }

    onExited() {
        this.animating = false
    }

    next() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === this.state.items.length - 1
                ? 0
                : this.state.activeIndex + 1
        this.setState({ activeIndex: nextIndex })
    }

    previous() {
        if (this.animating) return
        const nextIndex =
            this.state.activeIndex === 0
                ? this.state.items.length - 1
                : this.state.activeIndex - 1
        this.setState({ activeIndex: nextIndex })
    }

    goToIndex(newIndex) {
        if (this.animating) return
        this.setState({ activeIndex: newIndex })
    }

    onOpenModal = (e, m) => {
        e.stopPropagation();

        this.setState({
            ['open' + m]: true
        });
    };

    onCloseModal = () => {
        this.setState({
            open0: false,
            open1: false,
            open2: false,
            open3: false
        });
    };

    render() {
        const { activeIndex, open0, open1, open2, open3 } = this.state

        let iOS = false
        try {
            iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        } catch (e) { }

        const slides = [
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={0}
                style={{ opacity: 1 }}
            >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <span className="header-title">
                                Pourquoi Irriglobe
                            </span>
                            <span className="header-text">
                                Un service de qualité,<br />
                                la paix d'exprit assurée!
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <Modal open={open0} onClose={this.onCloseModal} little closeIconSize={50} closeIconSvgPath={<path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="white" />} classNames={{ overlay: 'modal-overlay', closeIcon: 'modal-close-icon' }}>
                                <video className="home-video" controls controlsList="nodownload" >
                                    <track default kind="captions"
                                        srcLang="fr"
                                        src="" />
                                    <source src={PourquoiIrriglobewebm} type="video/webm" />
                                    <source src={PourquoiIrriglobemp4} type="video/mp4" />
                                </video>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="button-container">
                            <div style={{ position: 'relative' }}>
                                <Button
                                    color="primary"
                                    size="lg"
                                    round
                                    onClick={() => navigateTo('/compagnie/expertises')}
                                    style={{ paddingRight: 75 }}
                                >
                                    En savoir plus
                                </Button>
                                <Button onClick={(e) => this.onOpenModal(e, '0')} color="primary" size="sm" round icon style={{ margin: "0 0 0 10px", position: "absolute", left: 146, top: 13, height: 50, minWidth: 50, width: 50, borderRadius: 50 }}>
                                    <i className="now-ui-icons media-1_button-play"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CarouselItem>
            ,
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={1}
                style={{ opacity: 1 }}
            >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <span className="header-title">
                                Contrôleur à distance Wifi
                            </span>
                            <span className="header-text">
                                En savoir plus sur notre<br />
                                contrôleur à distance Rachio
                            </span>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <Modal open={open1} onClose={this.onCloseModal} little closeIconSize={50} closeIconSvgPath={<path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="white" />} classNames={{ overlay: 'modal-overlay', closeIcon: 'modal-close-icon' }}>
                                <video className="home-video" controls controlsList="nodownload">
                                    <track default kind="captions"
                                        srcLang="fr"
                                        src="" />
                                    <source src={ControleurWifiwebm} type="video/webm" />
                                    <source src={ControleurWifimp4} type="video/mp4" />
                                </video>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="button-container">
                            <div style={{ position: 'relative' }}>
                                <Button
                                    color="primary"
                                    size="lg"
                                    round
                                    onClick={() => navigateTo('/produits/rachio')}
                                    style={{ paddingRight: 75 }}
                                >
                                    En savoir plus
                            </Button>
                                <Button onClick={(e) => this.onOpenModal(e, '1')} color="primary" size="sm" round icon style={{ margin: "0 0 0 10px", position: "absolute", left: 146, top: 13, height: 50, minWidth: 50, width: 50, borderRadius: 50 }}>
                                    <i className="now-ui-icons media-1_button-play"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CarouselItem>
            ,
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={2}
                style={{ opacity: 1 }}
            >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <span className="header-title">
                                Senseur de pluie
                            </span>
                            <span className="header-text">
                                En savoir plus sur notre<br />
                                Senseur de pluie Irritrol
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <Modal open={open2} onClose={this.onCloseModal} little closeIconSize={50} closeIconSvgPath={<path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="white" />} classNames={{ overlay: 'modal-overlay', closeIcon: 'modal-close-icon' }}>
                                <video className="home-video" controls controlsList="nodownload">
                                    <track default kind="captions"
                                        srcLang="fr"
                                        src="" />
                                    <source src={SenseurPluiewebm} type="video/webm" />
                                    <source src={SenseurPluiemp4} type="video/mp4" />
                                </video>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="button-container">
                            <div style={{ position: 'relative' }}>
                                <Button color="primary" size="lg" round onClick={() => navigateTo('/produits/hunter/sonde-solar-sync')} style={{ paddingRight: 75 }}>
                                    En savoir plus
                            </Button>
                                <Button onClick={(e) => this.onOpenModal(e, '2')} color="primary" size="sm" round icon style={{ margin: "0 0 0 10px", position: "absolute", left: 146, top: 13, height: 50, minWidth: 50, width: 50, borderRadius: 50 }}>
                                    <i className="now-ui-icons media-1_button-play"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CarouselItem>
            ,
            <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={3}
                style={{ opacity: 1 }}
            >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <span className="header-title">
                                Gicleur asperseur et Gicleur rotatif
                            </span>
                            <span className="header-text">
                                En savoir plus sur notre<br />
                                Gicleur asperseur et Gicleur rotatif
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <Modal open={open3} onClose={this.onCloseModal} little closeIconSize={50} closeIconSvgPath={<path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="white" />} classNames={{ overlay: 'modal-overlay', closeIcon: 'modal-close-icon' }}>
                                <video className="home-video" controls controlsList="nodownload">
                                    <track default kind="captions"
                                        srcLang="fr"
                                        src="" />
                                    <source src={AsperseurGicleurwebm} type="video/webm" />
                                    <source src={AsperseurGicleurmp4} type="video/mp4" />
                                </video>
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12} className="button-container">
                            <div style={{ position: 'relative' }}>
                                <Button
                                    color="primary"
                                    size="lg"
                                    round
                                    onClick={() => navigateTo('/produits/toro')}
                                    style={{ paddingRight: 75 }}
                                >
                                    En savoir plus
                            </Button>
                                <Button onClick={(e) => this.onOpenModal(e, '3')} color="primary" size="sm" round icon style={{ margin: "0 0 0 10px", position: "absolute", left: 146, top: 13, height: 50, minWidth: 50, width: 50, borderRadius: 50 }}>
                                    <i className="now-ui-icons media-1_button-play"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </CarouselItem>
        ];

        return (
            <>
                <Parallax
                    disabled={this.props.mobile}
                    className="page-header header-filter clear-filter filter-black-color homepage"
                >
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={120000}
                    >
                        {slides}
                        <CarouselControl
                            direction="prev"
                            directionText="Previous"
                            onClickHandler={this.previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={this.next}
                        />
                    </Carousel>
                    <Background>
                        {!iOS ? (
                            <video playsInline autoPlay muted loop className="bgvideo" id="bgvideo">
                                <track default kind="captions"
                                    srcLang="fr"
                                    src="" />
                                <source src={Introwebm} type="video/webm" />
                                <source src={Intromp4} type="video/mp4" />
                            </video>
                        ) : (
                                <div className="bgvideo" />
                            )}
                    </Background>
                </Parallax>
            </>
        );
    };
}

export default HomeSlider;
